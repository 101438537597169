import { CircularProgress, Grid } from '@mui/material';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import './index.css';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import '@draft-js-plugins/emoji/lib/plugin.css';
import reportWebVitals from './reportWebVitals';
import App from './components/App/App';
import { HelmetProvider } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers';
import Head from './components/App/Head';
import { AssetsProvider, NotificationsProvider, AnalyticsProvider, analytics } from '@borrowworks/react-core-lib';
import { ExtendedLuxonAdapter, ExtendedErrorBoundary } from '@borrowworks/react-mui';
import env from '@beam-australia/react-env';
import { getCdnAsset } from 'config';
import VersionCheck from 'components/App/VersionCheck';
import { generateUniqueID } from 'web-vitals/dist/modules/lib/generateUniqueID';
import { QueryClient, QueryClientProvider } from 'react-query';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';
import config from './config';
import GlobalErrorFallback from 'components/ErrorFallbacks/GlobalErrorFallback';
import AuthProvider from 'contexts/AuthProvider';
import { FeedbackProvider } from 'contexts/FeedbackProvider';

const { transformId, restrictAdditionalProperties, isNewProperty } = analytics;

const queryProvider = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const getConfig = async () => {
    const portfolio = env('PORTFOLIO_NAME');
    const conf = await import(`./themes/${portfolio}/asset-config`);

    return conf.default;
};

Sentry.init({
    dsn: config.sentry.DSN,
    release: process.env.REACT_APP_BUILD_VERSION ?? 'development',
    // This variable is injected at build time.
    environment: env('ENVIRONMENT'),
    debug: false,
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            ),
        }),
        new CaptureConsole({
            levels: ['error'],
        }),
    ],
    tracesSampleRate: 1.0,
});
const root = createRoot(document.getElementById('root'));
root.render(
    <ExtendedErrorBoundary FallbackComponent={GlobalErrorFallback} onReset={() => window.location.reload()}>
        <AuthProvider oidcWellKnownUri={env('OIDC_WELL_KNOWN_URL')}>
            <QueryClientProvider client={queryProvider}>
                <VersionCheck>
                    <AssetsProvider remoteConfig={getCdnAsset(`/agent-portal/config.js?${generateUniqueID()}`)} localConfig={getConfig()}>
                        <AnalyticsProvider
                            config={{
                                writeKey: config.segment.WRITE_KEY,
                                debug: config.segment.DEBUG,
                                transforms: {
                                    track: [restrictAdditionalProperties],
                                    all: [transformId],
                                },
                                customKeywords: [isNewProperty],
                            }}
                        >
                            <Suspense
                                fallback={
                                    <Grid container justifyContent="center" alignItems="center">
                                        <Grid item>
                                            <CircularProgress size={18} />
                                        </Grid>
                                    </Grid>
                                }
                            >
                                <React.StrictMode>
                                    <BrowserRouter>
                                        <HelmetProvider>
                                            <Head />
                                            <LocalizationProvider dateAdapter={ExtendedLuxonAdapter}>
                                                <NotificationsProvider>
                                                    <FeedbackProvider>
                                                        <App />
                                                    </FeedbackProvider>
                                                </NotificationsProvider>
                                            </LocalizationProvider>
                                        </HelmetProvider>
                                    </BrowserRouter>
                                </React.StrictMode>
                            </Suspense>
                        </AnalyticsProvider>
                    </AssetsProvider>
                </VersionCheck>
            </QueryClientProvider>
        </AuthProvider>
    </ExtendedErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
