import axios from 'axios';

const httpClient = axios.create();

httpClient.interceptors.request.use((config) => {
    const token = JSON.parse(localStorage.getItem('ROCP_idToken'));
    if (token) {
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${token}`,
        };
    }

    return config;
});

export const searchLoans = (values, page = 1, pageSize = 50) => {
    return httpClient.get(`/lms/api/v1/loans`, { params: { ...values, page, pageSize } });
};

export const getLoansByExtUserId = (extUserId) => {
    return httpClient.get(`/lms/api/v1/loans/${extUserId}/loans`);
};

export const getLoanPaymentHistory = (loanNumber) => {
    return httpClient.get(`/lms/api/v1/loans/${loanNumber}/payments/history`);
};

export const getLoanPaymentsDue = (loanNumber) => {
    return httpClient.get(`/lms/api/v1/loans/${loanNumber}/payments`);
};

export const getLoanPayoffQuotes = (loanNumber, paymentDate, startOn = undefined) => {
    return httpClient.get(`/lms/api/v1/loans/${loanNumber}/payments/payoff`, { params: { paymentDate, startOn } });
};

export const getLoanPayments = (loanNumber) => {
    return httpClient.get(`/lms/api/v1/loans/${loanNumber}/payments/info`);
};

export const cancelAchPayment = (loanNumber, achId) => {
    return httpClient.delete(`/lms/api/v1/loans/${loanNumber}/ach/payments/${achId}/cancel`);
};

export const getCancelLoanInfo = (loanNumber, cancelDate) => {
    return httpClient.get(`/lms/api/v1/loans/${loanNumber}/cancel`, { params: { cancelDate } });
};

export const cancelLoan = (loanNumber, values) => {
    return httpClient.post(`/lms/api/v1/loans/${loanNumber}/cancel`, values);
};

export const getLmsPrivileges = () => httpClient.get(`/lms/api/v1/privileges`);
