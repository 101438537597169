import env from '@beam-australia/react-env';

const envConfig = {
    development: {
        cdn: {
            URL: 'https://bw-portal-cdn.s3.amazonaws.com/integration',
        },
        sentry: {
            DSN: 'https://bd5a077acae4476abb946becd2a70303@o112787.ingest.sentry.io/6682910',
        },
        subscriptionCenter: {
            URL: 'https://int-subscriptions.brightlending.com',
        },
        debitProviderKey: 'x895N42',
        arPortal: {
            baseURL: 'https://stg-resolve.brightlending.com',
        },
        segment: {
            WRITE_KEY: 'S9kFRTq7jpC0puezwsLXaCbU87iDRjhB',
            DEBUG: true,
        },
        refiner: {
            projectId: 'd2a75d90-7f33-11ee-b474-21cca47f850d',
            loanCancellationSurveyId: 'f9a9a2a0-b549-11ee-98f7-33bfdc6cb1b3',
            enableSurveys: true,
        },
        enableRefinance: true,
        enableCleanContact: true,
        enableAutoAchRevoke: true,
        enableCloneApp: true,
        enableChangePayFrequency: true,
        sendReapplyLink: {
            enableSms: false,
            enableEmail: true,
        },
        cloneLink: 'https://int-selfservice.brightlending.com/clone',
        teamLeadGroups: ['Bright Lending Supervisor'],
        hardshipDeferment: {
            enabled: true,
            maxAllowedMonthly: 1,
            maxAllowedSemiMonthly: 2,
            maxAllowedBiWeekly: 2,
            maxAllowedWeekly: 4,
        },
        waiveBalanceReasons: {
            deceased: 'Deceased',
            identityTheft: 'Identity Theft',
            activeMilitary: 'Active Military',
            suppressedJurisdiction: 'Suppressed Jurisdiction',
        },
        fees: {
            manualPaymentProcessing: 0,
        },
        futurePaymentsDocument: {
            maxListedPayments: 5,
            hideForFatalReturnLoans: true,
        },
        cancelLoan: {
            deadline: {
                hour: 15,
                minute: 0,
            },
            timeZone: 'America/Denver',
        },
        arbitration: {
            optOutEnabled: false,
            eligibilityDays: 21,
        },
    },
    integration: {
        cdn: {
            URL: 'https://bw-portal-cdn.s3.amazonaws.com/integration',
        },
        sentry: {
            DSN: 'https://bd5a077acae4476abb946becd2a70303@o112787.ingest.sentry.io/6682910',
        },
        subscriptionCenter: {
            URL: 'https://int-subscriptions.brightlending.com',
        },
        debitProviderKey: 'x895N42',
        arPortal: {
            baseURL: 'https://stg-resolve.brightlending.com',
        },
        segment: {
            WRITE_KEY: 'OWi5HQD7L1klXo9U8q7zCrCvL7AxUAZD',
            DEBUG: true,
        },
        refiner: {
            projectId: 'd2a75d90-7f33-11ee-b474-21cca47f850d',
            loanCancellationSurveyId: 'f9a9a2a0-b549-11ee-98f7-33bfdc6cb1b3',
            enableSurveys: true,
        },
        enableRefinance: true,
        enableCleanContact: true,
        enableAutoAchRevoke: true,
        enableCloneApp: true,
        enableChangePayFrequency: true,
        sendReapplyLink: {
            enableSms: false,
            enableEmail: true,
        },
        cloneLink: 'https://int-selfservice.brightlending.com/clone',
        teamLeadGroups: ['Bright Lending Supervisor'],
        hardshipDeferment: {
            enabled: true,
            maxAllowedMonthly: 1,
            maxAllowedSemiMonthly: 2,
            maxAllowedBiWeekly: 2,
            maxAllowedWeekly: 4,
        },
        waiveBalanceReasons: {
            deceased: 'Deceased',
            identityTheft: 'Identity Theft',
            activeMilitary: 'Active Military',
            suppressedJurisdiction: 'Suppressed Jurisdiction',
        },
        fees: {
            manualPaymentProcessing: 0,
        },
        futurePaymentsDocument: {
            maxListedPayments: 5,
            hideForFatalReturnLoans: true,
        },
        cancelLoan: {
            deadline: {
                hour: 15,
                minute: 0,
            },
            timeZone: 'America/Denver',
        },
        arbitration: {
            optOutEnabled: false,
            eligibilityDays: 21,
        },
    },
    staging: {
        cdn: {
            URL: 'https://bw-portal-cdn.s3.amazonaws.com/staging',
        },
        sentry: {
            DSN: 'https://bd5a077acae4476abb946becd2a70303@o112787.ingest.sentry.io/6682910',
        },
        subscriptionCenter: {
            URL: 'https://stg-subscriptions.brightlending.com',
        },
        debitProviderKey: 'x895N42',
        arPortal: {
            baseURL: 'https://stg-resolve.brightlending.com',
        },
        segment: {
            WRITE_KEY: 'OWi5HQD7L1klXo9U8q7zCrCvL7AxUAZD',
            DEBUG: true,
        },
        refiner: {
            projectId: 'd2a75d90-7f33-11ee-b474-21cca47f850d',
            loanCancellationSurveyId: 'f9a9a2a0-b549-11ee-98f7-33bfdc6cb1b3',
            enableSurveys: true,
        },
        enableRefinance: true,
        enableCleanContact: true,
        enableAutoAchRevoke: true,
        enableCloneApp: true,
        enableChangePayFrequency: true,
        sendReapplyLink: {
            enableSms: false,
            enableEmail: true,
        },
        cloneLink: 'https://stg-selfservice.brightlending.com/clone',
        hardshipDeferment: {
            enabled: true,
            maxAllowedMonthly: 1,
            maxAllowedSemiMonthly: 2,
            maxAllowedBiWeekly: 2,
            maxAllowedWeekly: 4,
        },
        waiveBalanceReasons: {
            deceased: 'Deceased',
            identityTheft: 'Identity Theft',
            activeMilitary: 'Active Military',
            suppressedJurisdiction: 'Suppressed Jurisdiction',
        },
        fees: {
            manualPaymentProcessing: 0,
        },
        futurePaymentsDocument: {
            maxListedPayments: 5,
            hideForFatalReturnLoans: true,
        },
        cancelLoan: {
            deadline: {
                hour: 15,
                minute: 0,
            },
            timeZone: 'America/Denver',
        },
        arbitration: {
            optOutEnabled: false,
            eligibilityDays: 21,
        },
    },
    production: {
        cdn: {
            URL: 'https://bw-portal-cdn.s3.amazonaws.com/production',
        },
        sentry: {
            DSN: 'https://bd5a077acae4476abb946becd2a70303@o112787.ingest.sentry.io/6682910',
        },
        subscriptionCenter: {
            URL: 'https://subscriptions.brightlending.com',
        },
        debitProviderKey: '0N42ny3',
        arPortal: {
            baseURL: 'https://resolve.brightlending.com',
        },
        segment: {
            WRITE_KEY: 'XsHjD7WTANFPAqN9pEWinpFR1EU6yxID',
            DEBUG: false,
        },
        refiner: {
            projectId: 'd2a75d90-7f33-11ee-b474-21cca47f850d',
            loanCancellationSurveyId: 'f9a9a2a0-b549-11ee-98f7-33bfdc6cb1b3',
            enableSurveys: true,
        },
        enableRefinance: true,
        enableCleanContact: true,
        enableAutoAchRevoke: true,
        enableCloneApp: true,
        enableChangePayFrequency: false,
        sendReapplyLink: {
            enableSms: false,
            enableEmail: false,
        },
        cloneLink: 'https://selfservice.brightlending.com/clone',
        teamLeadGroups: [],
        hardshipDeferment: {
            enabled: true,
            maxAllowedMonthly: 1,
            maxAllowedSemiMonthly: 2,
            maxAllowedBiWeekly: 2,
            maxAllowedWeekly: 4,
        },
        waiveBalanceReasons: {
            deceased: 'Deceased',
            identityTheft: 'Identity Theft',
            activeMilitary: 'Active Military',
            suppressedJurisdiction: 'Suppressed Jurisdiction',
        },
        fees: {
            manualPaymentProcessing: 0,
        },
        futurePaymentsDocument: {
            maxListedPayments: 5,
            hideForFatalReturnLoans: true,
        },
        cancelLoan: {
            deadline: {
                hour: 15,
                minute: 0,
            },
            timeZone: 'America/Denver',
        },
        arbitration: {
            optOutEnabled: false,
            eligibilityDays: 21,
        },
    },
};

export default envConfig[env('ENVIRONMENT')];
