import React, { useEffect } from 'react';
import { Avatar, Box, capitalize, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import { useUser } from 'contexts/UserProvider';
import { useNavigate } from 'react-router';

const Home = () => {
    const { user } = useUser();
    const navigate = useNavigate();
    const redirect = localStorage.getItem('redirect')?.split('?')?.[0];

    useEffect(() => {
        if (!user) {
            return;
        }

        if (redirect !== '/') {
            localStorage.removeItem('redirect');
            navigate(redirect ?? '/');
        }
    }, [user, navigate, redirect]);

    if (!user) {
        return null;
    }

    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} lg={6}>
                <Card>
                    <CardContent>
                        <Box justifyContent="center" display="flex" sx={{ mb: 3 }}>
                            <Avatar
                                sx={{
                                    m: 2,
                                    width: '175px',
                                    height: '175px',
                                    backgroundColor: (theme) => theme.palette.primary.main,
                                    boxShadow: (theme) => `0 0 20px ${theme.palette.primary.main}`,
                                }}
                            >
                                <img src="logo.svg" alt="logo" style={{ width: 'auto', height: '75px' }} />
                            </Avatar>
                        </Box>
                        <Divider variant="middle" />
                        <Typography align="center" variant="h6">
                            Welcome {capitalize(user.username.split('.')[0])}!
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default Home;
