import React from 'react';
import { ErrorFallback, variantTypes } from '@borrowworks/react-mui';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, Button } from '@mui/material';

const { HORIZONTAL } = variantTypes;

const GlobalErrorFallback = (props) => {
    const { resetErrorBoundary, headerText, messageText, IconComponent, FooterComponent, variant } = props;

    const footer = FooterComponent ?? (
        <Box sx={{ mt: 1 }}>
            <Button variant="outlined" onClick={() => resetErrorBoundary()}>
                Refresh page
            </Button>
        </Box>
    );

    const errorIcon = IconComponent ?? (
        <ErrorIcon
            sx={{
                width: '175px',
                height: '175px',
                color: (theme) => theme.palette.primary.main,
            }}
        />
    );

    return (
        <ErrorFallback
            variant={variant ?? HORIZONTAL}
            headerText={headerText ?? 'Whoops... something went wrong'}
            messageText={
                messageText ??
                'An unexpected error has occurred. Please refresh the page and try again. If the issue persists, please contact an administrator.'
            }
            IconComponent={errorIcon}
            FooterComponent={footer}
        />
    );
};

export default GlobalErrorFallback;
