import React, { useCallback, useEffect, useMemo } from 'react';
import { getMe } from 'services/PandoraApiService';
import { ADMIN, ROLE_COMPLIANCE, ROLE_TEAM_LEAD, SUPER_ADMIN } from 'constants/Roles';
import { useToken } from 'contexts/AuthProvider';
import { useQuery } from 'react-query';
import Cookies from 'js-cookie';
import { getLmsPrivileges } from 'services/LoanManagementApiService';
import { some } from 'lodash';

const UserContext = React.createContext(null);

const getTokenCookieDomain = () => {
    if (window.location.hostname === 'localhost') {
        return window.location.hostname;
    }
    return `.${window.location.hostname.split('.').slice(1).join('.')}`;
};

export const UserProvider = ({ children }) => {
    const { idToken, idTokenData, login, logOut, loginInProgress, error } = useToken();

    const userAuthenticated = !loginInProgress && !!idTokenData;

    const { data: user, remove: clearUserData } = useQuery('fetch-user-info', async () => (await getMe())?.data?.user, {
        enabled: userAuthenticated,
        refetchInterval: 60000,
    });

    const { data: privileges } = useQuery('fetch-user-privileges', async () => (await getLmsPrivileges())?.data, {
        enabled: userAuthenticated,
        refetchInterval: 60000,
    });

    // Update the OneLogin token cookie any time our access token changes. This is needed for various redirects to other OneLogin apps
    useEffect(() => {
        if (!idToken) {
            return;
        }

        Cookies.set('token', idToken, { sameSite: 'none', secure: true, domain: getTokenCookieDomain() });
    }, [idToken]);

    const hasPrivilege = useCallback(
        (privilege) => {
            if (!privileges) {
                return false;
            }

            return some(privileges, { code: privilege });
        },
        [privileges]
    );

    const hasRoles = useCallback(
        (roles) => {
            if (!user?.roles) {
                return false;
            }

            if (typeof roles === 'string') {
                return user.roles.includes(roles);
            }

            return roles.some((r) => user.roles.includes(r));
        },
        [user?.roles]
    );

    const { isSuperAdmin, isAdmin, isComplianceUser, isTeamLead } = useMemo(
        () => ({
            isSuperAdmin: hasRoles(SUPER_ADMIN),
            isAdmin: hasRoles(ADMIN),
            isComplianceUser: hasRoles(ROLE_COMPLIANCE),
            isTeamLead: hasRoles(ROLE_TEAM_LEAD),
        }),
        [hasRoles]
    );

    const logout = useCallback(() => {
        if (loginInProgress) {
            return;
        }

        Cookies.remove('token', { domain: getTokenCookieDomain() });
        clearUserData();
        logOut();
    }, [clearUserData, logOut, loginInProgress]);

    const contextValue = useMemo(
        () => ({
            user,
            redirectLogin: login,
            logout,
            hasRoles,
            isAdmin,
            isSuperAdmin,
            isTeamLead,
            isComplianceUser,
            error,
            authenticated: userAuthenticated,
            hasPrivilege,
        }),
        [user, login, logout, hasRoles, isAdmin, isSuperAdmin, isComplianceUser, isTeamLead, error, userAuthenticated, hasPrivilege]
    );

    return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

export const useUser = () => React.useContext(UserContext);
