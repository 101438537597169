import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { useInterval } from 'use-interval';
import { Typography } from '@mui/material';

const DEFAULT_TYPOGRAPHY = {
    align: 'center',
    variant: 'body1',
};

const Clock = ({ typographyProps = {}, format, sx }) => {
    const [time, setTime] = useState(DateTime.now());

    useInterval(() => {
        setTime(DateTime.now());
    }, 500);

    const props = {
        ...DEFAULT_TYPOGRAPHY,
        ...typographyProps,
    };

    return (
        <Typography sx={sx} {...props}>
            {time.toLocaleString(format)}
        </Typography>
    );
};

Clock.defaultProps = {
    format: DateTime.DATETIME_SHORT_WITH_SECONDS,
};

export default Clock;
