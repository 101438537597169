import {
    AppBar,
    Avatar,
    Badge,
    Box,
    Button,
    Collapse,
    Drawer,
    Grid,
    IconButton,
    Toolbar,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useUser } from 'contexts/UserProvider';
import Navigation from './Navigation';
import { Helmet } from 'react-helmet-async';
import { Brightness4, Brightness7, Logout, Menu } from '@mui/icons-material';
import { stringAvatar } from 'util/StringUtils';
import { useAssets } from '@borrowworks/react-core-lib';
import { useLocalAsset } from 'config';
import { SUPER_ADMIN, ADMIN } from 'constants/Roles';
import Clock from 'components/App/Clock';
import { startCase, toLower } from 'lodash/string';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.5s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(1.1)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(1.4)',
            opacity: 0,
        },
    },
}));

const useStyles = makeStyles((theme) => ({
    logo: {
        width: 150,
        filter: theme.palette.logo ? theme.palette.logo.filter : 'brightness(0) invert(1)',
        marginLeft: theme.spacing(-2),
    },
    grow: {
        flexGrow: 1,
    },
}));

const TitleBar = ({ children, darkModeActive, setDarkMode }) => {
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    const classes = useStyles();
    const { user, hasRoles } = useUser();
    const assets = useAssets();

    const [title, setTitle] = useState('');
    const displayTitle = title.split(' |');
    const navigate = useNavigate();

    const logoutDialogRef = useRef(null);

    const [drawerOpen, setDrawerOpen] = useState(isMobile);

    const sidebarBg = useLocalAsset('bg_fractal.png');
    const roleString =
        user?.roles
            ?.map((r) => startCase(toLower(r.replace('ROLE_', ''))))
            .reverse()
            .join(', ') ?? '';

    // Not really necessary, but if you resize your window this will dynamically change the drawer type.
    useEffect(() => {
        setDrawerOpen(isMobile);
    }, [isMobile]);

    return (
        <Box sx={{ display: 'flex' }}>
            <Helmet onChangeClientState={(a) => setTitle(a.title)} />
            <Box component="nav" sx={{ width: { lg: 240 }, flexShrink: { sm: 0 } }}>
                <Drawer
                    onClose={() => setDrawerOpen(true)}
                    variant={isMobile ? 'temporary' : 'permanent'}
                    sx={{
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            zIndex: 1150,
                            width: 240,
                            mt: { xs: 7, sm: 8, lg: 0 },
                            borderRight: 'none',
                            boxShadow: '2px 0 10px -4px #060606',
                        },
                    }}
                    open={!drawerOpen}
                >
                    <AppBar
                        position="relative"
                        sx={{
                            backgroundImage: `url("${sidebarBg}")`,
                            backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#202020' : theme.palette.sidebar.backgroundColor),
                        }}
                    >
                        <Toolbar justifyContent="center" display="flex" component={Box}>
                            <Button to="/" component={RouterLink}>
                                <img src={assets.brandLogo} className={classes.logo} alt="logo" />
                            </Button>
                        </Toolbar>
                        <Collapse in={!!user} orientation="vertical">
                            <Toolbar sx={{ paddingLeft: 0 }}>
                                {user && (
                                    <Grid container justifyContent="start" alignContent="center" alignItems="center">
                                        <Grid item>
                                            <StyledBadge
                                                overlap="circular"
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                variant="dot"
                                                invisible={roleString.length <= 0}
                                                color={hasRoles(SUPER_ADMIN) ? 'info' : hasRoles(ADMIN) ? 'warning' : 'default'}
                                            >
                                                <Tooltip title={roleString} placement="top">
                                                    <Avatar {...stringAvatar(user.username)} sx={{ height: 32, width: 32 }} />
                                                </Tooltip>
                                            </StyledBadge>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="overline" color="inherit" display="inline" align="center">
                                                {user.username}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </Toolbar>
                        </Collapse>
                    </AppBar>
                    <Navigation />
                </Drawer>
            </Box>
            <Box component="main" sx={{ flexGrow: 1 }}>
                <AppBar position="sticky" sx={{ zIndex: 1150, boxShadow: '0px 4px 2px -2px rgb(0 0 0 / 20%)' }}>
                    <Toolbar>
                        {isMobile && (
                            <IconButton onClick={() => setDrawerOpen((o) => !o)} size="large">
                                <Menu />
                            </IconButton>
                        )}
                        <Typography variant="h6">{displayTitle.length > 1 ? displayTitle[0] : null}</Typography>
                        <span className={classes.grow} />
                        {!isMobile && <Clock sx={{ mr: 3 }} />}
                        <IconButton onClick={setDarkMode} color="inherit" aria-label="Darkmode" size="large">
                            {darkModeActive ? <Brightness7 /> : <Brightness4 />}
                        </IconButton>
                        {user && (
                            <Tooltip title="Log Out">
                                <IconButton
                                    onClick={() => logoutDialogRef.current?.show()}
                                    color="inherit"
                                    aria-label="Logout"
                                    size="large"
                                >
                                    <Logout />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Toolbar>
                </AppBar>
                {children}
            </Box>
            <ConfirmationDialog
                ref={logoutDialogRef}
                onAction={(action) => action && navigate('/logout')}
                title="Log Out"
                message="Are you sure you want to log out of the system?"
                maxWidth="md"
            />
        </Box>
    );
};

export default TitleBar;
