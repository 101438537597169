import React, { useState, useEffect } from 'react';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import {
    AccountBalance,
    AssignmentInd,
    AssignmentLate,
    Business,
    Campaign,
    CloudQueue,
    DriveEta,
    DynamicForm,
    ExpandMore,
    Explore,
    Fingerprint,
    Flag,
    History,
    InsertDriveFile,
    LocalOffer,
    Mail,
    Map,
    NavigateNext,
    NoteAdd,
    NotInterested,
    ReceiptLong,
    Search,
    Settings,
    ShoppingCart,
    Source,
    Tune,
    UploadFile,
    Folder,
    RequestQuote,
    Add,
    CalendarMonth,
    PriceChange,
    LocalLibrary,
    AlternateEmail,
} from '@mui/icons-material';
import { useUser } from 'contexts/UserProvider';
import { useLocation } from 'react-router';
import { ADMIN, ROLE_FINANCE, SUPER_ADMIN } from 'constants/Roles';

const navItems = [
    {
        title: 'Applications',
        children: [
            { title: 'Search Applications', to: '/applications', icon: <Search /> },
            { title: 'Create Application', to: '/applications/new', icon: <NoteAdd />, roles: [SUPER_ADMIN, ADMIN] },
        ],
        icon: <AssignmentInd />,
    },
    {
        title: 'Loans',
        children: [{ title: 'Search Loans', to: '/loans/search', icon: <Search /> }],
        icon: <RequestQuote />,
    },
    {
        title: 'Lead Providers',
        children: [
            { title: 'Lead Providers', to: '/leadproviders', icon: <Business />, roles: [SUPER_ADMIN, ADMIN, ROLE_FINANCE] },
            { title: 'CPL History', to: '/leadprovidercploverride', icon: <History />, roles: [SUPER_ADMIN, ADMIN, ROLE_FINANCE] },
        ],
        icon: <Business />,
        roles: [SUPER_ADMIN, ADMIN, ROLE_FINANCE],
    },
    {
        title: 'Offers',
        children: [
            { title: 'Create Offer', to: '/offers/new', icon: <Add />, roles: [SUPER_ADMIN, ADMIN] },
            { title: 'Bulk Uploads', to: '/offers/upload', icon: <UploadFile />, roles: [SUPER_ADMIN, ADMIN] },
            { title: 'Campaigns', to: '/campaigns', icon: <Campaign />, roles: [SUPER_ADMIN, ADMIN] },
            { title: 'Available Offers', to: '/offers', icon: <LocalOffer /> },
        ],
        icon: <Mail />,
    },
    {
        title: 'Stores',
        children: [
            //{title: 'Available Stores', to: '/stores', icon: <ShoppingBasket/>},
            { title: 'Document Templates', to: '/stores/documents', icon: <InsertDriveFile /> },
        ],
        icon: <ShoppingCart />,
        roles: [SUPER_ADMIN, ADMIN],
    },
    {
        title: 'Blacklists',
        children: [
            { title: 'Change History', to: '/blacklists/history', icon: <History /> },
            { title: 'ABA', to: '/blacklists/aba', icon: <ReceiptLong /> },
            { title: 'Bank Account', to: '/blacklists/bankaccount', icon: <AccountBalance /> },
            { title: 'Drivers License', to: '/blacklists/dl', icon: <DriveEta /> },
            { title: 'Postal Code', to: '/blacklists/postalcode', icon: <Explore /> },
            { title: 'SSN', to: '/blacklists/ssn', icon: <Fingerprint /> },
            { title: 'States', to: '/blacklists/states', icon: <Map /> },
        ],
        icon: <NotInterested />,
    },
    {
        title: 'Underwriting',
        children: [
            //{title: 'Checklists', to: '/checklists', icon: <ReceiptLong/>},
            { title: 'Reasons', to: '/underwriting/reasons', icon: <AssignmentLate /> },
            { title: 'Strategies', to: '/underwriting/strategies', icon: <Source /> },
            { title: 'Strategy Families', to: '/underwriting/strategyfamilies', icon: <Folder /> },
            { title: 'Strategy Selections', to: '/underwriting/strategyselection', icon: <DynamicForm /> },
            { title: 'Products', to: '/underwriting/products', icon: <PriceChange /> },
        ],
        icon: <Tune />,
        roles: [SUPER_ADMIN, ADMIN],
    },
    {
        title: 'Resources',
        icon: <LocalLibrary />,
        children: [{ title: 'Email Catalog', to: '/resources/emails', icon: <AlternateEmail /> }],
    },
    {
        title: 'Settings',
        icon: <Settings />,
        children: [
            { title: 'Flags', to: '/settings/flags', icon: <Flag /> },
            { title: 'Webhooks', to: '/settings/webhooks', icon: <CloudQueue /> },
            { title: 'Holidays', to: '/holidays', icon: <CalendarMonth /> },
        ],
        roles: [SUPER_ADMIN, ADMIN],
    },
];

const Navigation = () => {
    const { user, hasRoles } = useUser();
    const { pathname } = useLocation();

    if (!user) {
        return null;
    }

    return (
        <List>
            {navItems.map((item, idx) => (
                <NavigationListItem key={idx} item={item} currentPath={pathname} checkRoles={hasRoles} />
            ))}
        </List>
    );
};

const NavigationListItem = ({ item, subItem, currentPath, checkRoles }) => {
    const [open, setOpen] = useState(false);

    const singleObject = !item.children;

    useEffect(() => {
        for (let c of item.children || []) {
            if (c.to === currentPath) {
                setOpen(true);
                return;
            }
        }
    }, [currentPath, item]);

    if (item.roles && !checkRoles(item.roles)) {
        return null;
    }

    if (singleObject) {
        return (
            <ListItemButton
                selected={item.to === currentPath}
                component={!item.to ? null : RouterLink}
                to={item.to}
                sx={{ pl: subItem ? 5 : 2 }}
                dense={subItem}
                divider={subItem}
            >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                    primary={item.title}
                    primaryTypographyProps={{
                        variant: subItem ? 'body2' : 'body1',
                    }}
                />
            </ListItemButton>
        );
    }

    return (
        <>
            <ListItemButton onClick={() => setOpen((o) => !o)}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                    primary={item.title}
                    primaryTypographyProps={{
                        variant: subItem ? 'body2' : 'body1',
                    }}
                />
                {open ? <ExpandMore /> : <NavigateNext />}
            </ListItemButton>
            {item.children.map((child, idx) => (
                <Collapse key={idx} in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <NavigationListItem item={child} subItem currentPath={currentPath} checkRoles={checkRoles} />
                    </List>
                </Collapse>
            ))}
        </>
    );
};

export default Navigation;
