import { blue, blueGrey, deepPurple, green, grey, orange, red } from '@mui/material/colors';

export const APPLICATION_STATUS = {
    DRAFT: 'draft',
    WITHDRAWN: 'withdrawn',
    PENDING: 'pending',
    ACCEPTED: 'accepted',
    REVIEW: 'review',
    ORIGINATED: 'originated',
    REJECTED: 'rejected',
    EXCEPTION: 'exception',
    IGNORED: 'ignored',
    APPROVED: 'approved',
    VOIDED: 'voided',
};

export const APPLICATION_STATUS_COLOR = {
    [APPLICATION_STATUS.DRAFT]: blueGrey[300],
    [APPLICATION_STATUS.PENDING]: grey[300],
    [APPLICATION_STATUS.ACCEPTED]: green[500],
    [APPLICATION_STATUS.REVIEW]: orange[600],
    [APPLICATION_STATUS.REJECTED]: red[600],
    [APPLICATION_STATUS.WITHDRAWN]: grey[300],
    [APPLICATION_STATUS.EXCEPTION]: red[300],
    [APPLICATION_STATUS.IGNORED]: grey[300],
    [APPLICATION_STATUS.APPROVED]: blue[500],
    [APPLICATION_STATUS.VOIDED]: grey[300],
    [APPLICATION_STATUS.ORIGINATED]: deepPurple[400],
};

export const APPLICATION_REASON_CODE = {
    REVIEW100: 'REVIEW100',
};
