import { capitalize } from 'lodash/string';

export function stringAvatar(name) {
    const nameSplit = name.split('.');

    // Handle cases where username does not contain "first.last"
    if (nameSplit.length < 2) {
        return { children: name.substring(0, 2) };
    }

    return { children: `${nameSplit[0][0]}${nameSplit[1][0]}` };
}

export function nameAvatar(name) {
    const nameSplit = name.split(' ');

    // Handle cases where username does not contain "first last"
    if (nameSplit.length < 2) {
        return { children: name };
    }

    return { children: `${nameSplit[0][0]}${nameSplit[1][0]}` };
}

export function capitalizeFirst(str) {
    return capitalize(str);
}

export function formatCurrency(str, useSign = true) {
    if (typeof str === 'string') {
        str = str.replace(/[^0-9.]/, '');
    }
    if (!useSign) {
        return new Intl.NumberFormat('en-US', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        }).format(str);
    }

    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(str);
}
