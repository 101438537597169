import React from 'react';
import { useUser } from 'contexts/UserProvider';
import NotFound from 'components/App/NotFound';

export const RequireRoles = ({ roles, children }) => {
    const { hasRoles } = useUser();

    const hasAccess = !roles || hasRoles(roles);

    if (!hasAccess) {
        return <NotFound />;
    }

    return children;
};
