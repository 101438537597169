import { useState } from 'react';
import axios from 'axios';
import { useInterval } from 'use-interval';
import env from '@beam-australia/react-env';

/**
 * Kinda hacky... but without going with PWA hell this will check the version.txt file that is included in the docker
 * builds and will refresh if the version in local storage is different from the requested version.
 * @param children
 * @returns {null|*}
 * @constructor
 */
const VersionCheck = ({ children }) => {
    const [checkInProgress, setCheckInProgress] = useState(true);

    const isDevelopment = env('ENVIRONMENT') === 'development';

    useInterval(
        () => {
            const checkVersion = async () => {
                const buildVersion = localStorage.getItem('buildver');

                try {
                    const currentAppVersionReq = await axios.get('/version.txt');

                    if (!currentAppVersionReq.headers['content-type']?.includes('text/plain')) {
                        console.warn('got bad version from server. ignoring check');
                        return;
                    }

                    const currentAppVersion = currentAppVersionReq.data;

                    if (!buildVersion) {
                        console.debug('build version not set. setting it.');
                        localStorage.setItem('buildver', currentAppVersion);
                        setCheckInProgress(false);
                        return;
                    }

                    if (currentAppVersion !== buildVersion) {
                        localStorage.setItem('buildver', currentAppVersion);
                        console.debug('** app version change detected. reloading...');
                        if ('caches' in window) {
                            caches.keys().then((names) => {
                                // Delete all the cache files
                                names.forEach((name) => {
                                    caches.delete(name);
                                });
                            });

                            // Makes sure the page reloads. Changes are only visible after you refresh.
                            window.location.reload(true);
                        }
                    }
                    setCheckInProgress(false);
                } catch (err) {
                    console.error(err);
                    setCheckInProgress(false);
                }
            };

            checkVersion();
        },
        isDevelopment ? null : 30000,
        true
    );

    if (checkInProgress && !isDevelopment) {
        return null;
    }

    return children;
};

export default VersionCheck;
