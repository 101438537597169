import React from 'react';
import { useUser } from 'contexts/UserProvider';
import { Navigate, Outlet } from 'react-router';
import { useToken } from 'contexts/AuthProvider';

const AuthenticatedOutlet = () => {
    const { authenticated } = useUser();
    const { loginInProgress } = useToken();

    if (!authenticated) {
        // This guard will get hit when processing the PKCE auth_code flow. We need to check with the context that we
        // are not in the process of authentication before setting the redirect
        if (!loginInProgress && (!localStorage.getItem('redirect') || localStorage.getItem('redirect') === '/')) {
            localStorage.setItem('redirect', window.location.pathname + window.location.search);
        }
        return <Navigate to="/login" />;
    }

    return <Outlet />;
};

export default AuthenticatedOutlet;
