import SessionTimer from 'components/Session/SessionTimer';
import React, { lazy } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Route, Routes } from 'react-router';
import * as Sentry from '@sentry/react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import TitleBar from './TitleBar';
import NotFound from './NotFound';
import { UserProvider } from 'contexts/UserProvider';
import ApplicationList from 'components/Applications/ApplicationList';
import { createTheme } from '@mui/material/styles';
import { useCallback, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { responsiveFontSizes } from '@mui/material';
import { ErrorDialogProvider } from '@borrowworks/react-mui';
import { Snackbar } from '@borrowworks/react-mui';
import { useAssets } from '@borrowworks/react-core-lib';
import { RequireRoles } from 'components/Auth/RequireRoles';
import Home from 'components/Home/Home';
import { ADMIN, SUPER_ADMIN, ROLE_FINANCE } from 'constants/Roles';
import ProductList from 'components/Underwriting/ProductList';
import EditProduct from 'components/Underwriting/EditProduct';
import RouterErrorFallback from 'components/ErrorFallbacks/RouterErrorFallback';
import { ExtendedErrorBoundary } from '@borrowworks/react-mui';
import { useLocation } from 'react-router';
import AuthenticatedOutlet from 'components/Router/AuthenticatedOutlet';

const Login = lazy(() => import('components/Auth/Login'));
const Logout = lazy(() => import('components/Auth/Logout'));
const ApplicationRouter = lazy(() => import('routers/Applications/ApplicationRouter'));
const CreateApplication = lazy(() => import('components/Applications/CreateApplication'));
const RefinanceApplication = lazy(() => import('components/Applications/RefinanceApplication'));
const CloneApplication = lazy(() => import('components/Applications/CloneApplication'));
const BulkUploadList = lazy(() => import('components/BulkUploads/BulkUploadList'));
const EditBulkUpload = lazy(() => import('components/BulkUploads/EditBulkUpload'));
const CreateBulkUpload = lazy(() => import('components/BulkUploads/CreateBulkUpload'));
const CampaignList = lazy(() => import('components/Campaigns/CampaignList'));
const EditCampaign = lazy(() => import('components/Campaigns/EditCampaign'));
const OffersList = lazy(() => import('components/Offers/OffersList'));
const EditOffer = lazy(() => import('components/Offers/EditOffer'));
const CreateOffer = lazy(() => import('components/Offers/CreateOffer'));
const LeadProviderCPLOverride = lazy(() => import('components/LeadProviders/LeadProviderCPLOverride'));
const LeadProviderCPLOverrideList = lazy(() => import('components/LeadProviders/LeadProviderCPLOverrideList'));
const LeadProviderList = lazy(() => import('components/LeadProviders/LeadProviderList'));
const LeadProvider = lazy(() => import('components/LeadProviders/LeadProvider'));
const StrategySelection = lazy(() => import('components/Underwriting/StrategySelection'));
const StrategySelectorHistory = lazy(() => import('components/Underwriting/StrategySelectorHistory'));
const StrategyHistory = lazy(() => import('components/Underwriting/StrategyHistory'));
const StrategyList = lazy(() => import('components/Underwriting/StrategyList'));
const EditStrategy = lazy(() => import('components/Underwriting/EditStrategy'));
const CreateStrategy = lazy(() => import('components/Underwriting/CreateStrategy'));
const FamilyList = lazy(() => import('components/Underwriting/Families/FamilyList'));
const EditFamily = lazy(() => import('components/Underwriting/Families/EditFamily'));
const ReasonsList = lazy(() => import('components/Underwriting/Reasons/ReasonList'));
const EditReason = lazy(() => import('components/Underwriting/Reasons/EditReason'));
const DocumentTemplateList = lazy(() => import('components/Stores/Documents/DocumentTemplateList'));
const DocumentTemplateHistoryList = lazy(() => import('components/Stores/Documents/DocumentTemplateHistoryList'));
const DocumentTemplateHistory = lazy(() => import('components/Stores/Documents/DocumentTemplateHistory'));
const AbaBlacklist = lazy(() => import('components/Blacklists/Aba/AbaBlacklist'));
const BankAccountBlacklist = lazy(() => import('components/Blacklists/BankAccount/BankAccountBlacklist'));
const SsnBlacklist = lazy(() => import('components/Blacklists/Ssn/SsnBlacklist'));
const PostalCodeBlacklist = lazy(() => import('components/Blacklists/PostalCode/PostalCodeBlacklist'));
const DriversLicenseBlacklist = lazy(() => import('components/Blacklists/DriversLicense/DriversLicenseBlacklist'));
const StateBlacklist = lazy(() => import('components/Blacklists/State/StateBlacklist'));
const BlacklistHistory = lazy(() => import('components/Blacklists/BlacklistHistory'));
const FlagSettings = lazy(() => import('components/Settings/Flags'));
const WebhookList = lazy(() => import('components/Settings/Webhooks/WebhookList'));
const EditWebhook = lazy(() => import('components/Settings/Webhooks/EditWebhook'));
const LoanRouter = lazy(() => import('routers/Loans/LoanRouter'));
const LoanSearch = lazy(() => import('components/Loans/LoanSearch'));
const HolidayCalendar = lazy(() => import('components/Holidays/HolidayCalendar'));
const EmailCatalog = lazy(() => import('components/Resources/Email/EmailCatalog'));

const useStyles = makeStyles((theme) => ({
    content: {
        marginTop: 16,
        padding: 16,
        overflowX: 'clip',
    },
}));

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const App = () => {
    const classes = useStyles();

    const assets = useAssets();

    const location = useLocation();

    const [useDarkMode, setUseDarkMode] = useState(
        localStorage.getItem('use-dark-mode') === 'true' || !!localStorage.getItem('use-dark-mode')
    );

    const theme = React.useMemo(
        () =>
            responsiveFontSizes(
                createTheme({
                    ...assets.muiTheme,
                    palette: {
                        ...assets.muiTheme.palette,
                        mode: useDarkMode ? 'dark' : 'light',
                    },
                })
            ),
        [assets, useDarkMode]
    );

    const toggleDarkMode = useCallback(() => {
        setUseDarkMode((v) => {
            const newVal = !v;
            localStorage.setItem('use-dark-mode', `${newVal}`);

            return newVal;
        });
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <ErrorDialogProvider defaultErrorMessage="An unexpected error has occurred.">
                    <UserProvider>
                        <SessionTimer />
                        <CssBaseline />
                        <TitleBar darkModeActive={useDarkMode} setDarkMode={toggleDarkMode}>
                            <main className={classes.content}>
                                <React.Suspense fallback={<></>}>
                                    <ExtendedErrorBoundary
                                        key={location.pathname}
                                        FallbackComponent={RouterErrorFallback}
                                        fallbackProps={{ withCard: false }}
                                    >
                                        <SentryRoutes>
                                            <Route path="/login" element={<Login />} />
                                            <Route path="/logout" element={<Logout />} />
                                            <Route element={<AuthenticatedOutlet />}>
                                                <Route index element={<Home />} />
                                                <Route path="/applications" element={<ApplicationList />} />
                                                <Route
                                                    path="/applications/new"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <CreateApplication />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route path="/applications/:id/refinance" element={<RefinanceApplication />} />
                                                <Route path="/applications/:id/clone" element={<CloneApplication />} />
                                                <Route path="/applications/:id/*" element={<ApplicationRouter />} />
                                                <Route path="/offers" element={<OffersList />} />
                                                <Route
                                                    path="/offers/upload"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <BulkUploadList />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/offers/new"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <CreateOffer />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/offers/upload/new"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <CreateBulkUpload />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route path="/offers/:id" element={<EditOffer />} />
                                                <Route
                                                    path="/offers/upload/:id"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <EditBulkUpload />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/campaigns"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <CampaignList />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/campaigns/new"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <EditCampaign />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/campaigns/:id"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <EditCampaign />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/leadproviders"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN, ROLE_FINANCE]}>
                                                            <LeadProviderList />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/leadproviders/new"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <LeadProvider />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/leadproviders/:id"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN, ROLE_FINANCE]}>
                                                            <LeadProvider />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/leadproviders/:id/override"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN, ROLE_FINANCE]}>
                                                            <LeadProviderCPLOverride />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/leadprovidercploverride"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN, ROLE_FINANCE]}>
                                                            <LeadProviderCPLOverrideList />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/underwriting/strategyselection"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <StrategySelection />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/underwriting/strategyselection/:selectorId/history/:id"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <StrategySelectorHistory />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/underwriting/strategies"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <StrategyList />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/underwriting/strategies/new"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <CreateStrategy />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/underwriting/strategies/:id"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <EditStrategy />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/underwriting/strategies/:strategyId/history/:id"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <StrategyHistory />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/underwriting/strategyfamilies"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <FamilyList />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/underwriting/strategyfamilies/new"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <EditFamily />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/underwriting/strategyfamilies/:id"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <EditFamily />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/underwriting/reasons"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <ReasonsList />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/underwriting/reasons/new"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <EditReason />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/underwriting/reasons/:id"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <EditReason />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/underwriting/products"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <ProductList />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/underwriting/products/:id"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <EditProduct />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/underwriting/products/new"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <EditProduct />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/stores/documents"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <DocumentTemplateList />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/stores/documents/:id/history"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <DocumentTemplateHistoryList />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/stores/documents/:id/history/:historyId"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <DocumentTemplateHistory />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route path="/blacklists/aba" element={<AbaBlacklist />} />
                                                <Route path="/blacklists/bankaccount" element={<BankAccountBlacklist />} />
                                                <Route path="/blacklists/dl" element={<DriversLicenseBlacklist />} />
                                                <Route path="/blacklists/postalcode" element={<PostalCodeBlacklist />} />
                                                <Route path="/blacklists/ssn" element={<SsnBlacklist />} />
                                                <Route path="/resources/emails" element={<EmailCatalog />} />
                                                <Route path="/blacklists/states" element={<StateBlacklist />} />
                                                <Route path="/blacklists/history" element={<BlacklistHistory />} />
                                                <Route
                                                    path="/settings/flags"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <FlagSettings />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/settings/webhooks"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <WebhookList />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/settings/webhooks/new"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <EditWebhook />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route
                                                    path="/settings/webhooks/:id"
                                                    element={
                                                        <RequireRoles roles={[SUPER_ADMIN, ADMIN]}>
                                                            <EditWebhook />
                                                        </RequireRoles>
                                                    }
                                                />
                                                <Route path="/holidays" element={<HolidayCalendar />} />
                                                <Route path="/loans/search" element={<LoanSearch />} />
                                                <Route path="/loans/:id/*" element={<LoanRouter />} />
                                            </Route>
                                            <Route element={<NotFound />} />
                                        </SentryRoutes>
                                    </ExtendedErrorBoundary>
                                </React.Suspense>
                            </main>
                        </TitleBar>
                        <Snackbar />
                    </UserProvider>
                </ErrorDialogProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
