import React from 'react';
import { ErrorFallback } from '@borrowworks/react-mui';
import ErrorIcon from '@mui/icons-material/Error';
import { Card, CardContent } from '@mui/material';

const RouterErrorFallback = (props) => {
    const { headerText, messageText, IconComponent, FooterComponent, variant, withCard = true } = props;

    const errorIcon = IconComponent ?? (
        <ErrorIcon
            sx={{
                width: '175px',
                height: '175px',
                color: (theme) => theme.palette.primary.main,
            }}
        />
    );

    const errorFallbackBody = (
        <ErrorFallback
            variant={variant}
            headerText={headerText ?? 'Whoops... something went wrong'}
            messageText={messageText ?? 'An unexpected error has occurred. If the issue persists, please contact an administrator.'}
            IconComponent={errorIcon}
            FooterComponent={FooterComponent}
        />
    );

    return withCard ? (
        <Card>
            <CardContent>{errorFallbackBody}</CardContent>
        </Card>
    ) : (
        errorFallbackBody
    );
};

export default RouterErrorFallback;
