import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import env from '@beam-australia/react-env';

// eslint-disable-next-line no-unused-vars
import { AuthProvider as PkceProvider, AuthContext as TokenContext, TAuthConfig } from 'react-oauth2-code-pkce';

const AuthProvider = ({ oidcWellKnownUri, children }) => {
    const [oidcConfig, setOidcConfig] = useState(null);

    // Cannot use react-query here
    // See: https://github.com/soofstad/react-oauth2-pkce#after-redirect-back-from-auth-provider-with-code-no-token-request-is-made
    useEffect(() => {
        axios.get(oidcWellKnownUri).then(({ data }) => setOidcConfig(data));
    }, [oidcWellKnownUri]);

    if (!oidcConfig) {
        return null;
    }

    const scope = 'openid groups profile';
    const clientId = env('OIDC_CLIENT_ID');
    const redirectUri = window.location.origin;

    /**
     * @type TAuthConfig
     */
    const authConfig = {
        clientId,
        redirectUri,
        scope,
        authorizationEndpoint: oidcConfig?.authorization_endpoint,
        tokenEndpoint: oidcConfig?.token_endpoint,
        logoutEndpoint: oidcConfig?.end_session_endpoint,
        logoutRedirect: `${window.location.origin}`,
        autoLogin: false,
        decodeToken: localStorage.getItem('ROCP_token') !== '""',
    };

    return <PkceProvider authConfig={authConfig}>{children}</PkceProvider>;
};

export const useToken = () => useContext(TokenContext);

export default AuthProvider;
