import { APPLICATION_STATUS_COLOR } from 'constants/Application';
import React, { useEffect, useState } from 'react';
import { searchApplications } from 'services/PandoraApiService';
import { DataGrid } from '@mui/x-data-grid';
import { Close, RadioButtonUnchecked, Search, TaskAlt } from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';
import { Box, Chip, Grid, IconButton, InputAdornment, LinearProgress, Link, MenuItem, TextField, Toolbar, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { useQuery } from 'hooks/useQuery';
import { findIndex } from 'lodash/array';
import { Link as RouterLink } from 'react-router-dom';
import { DateTime } from 'luxon';

function getProgressColor(value) {
    //value from 0 to 1
    const hue = (value * 120).toString(10);
    return ['hsl(', hue, ',100%,40%)'].join('');
}

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 120,
        renderCell: ({ value }) => (
            <Link underline="none" color="inherit" to={`/applications/${value}`} component={RouterLink}>
                {value}
            </Link>
        ),
    },
    {
        field: 'checklistItems',
        headerName: 'Checklists',
        width: 160,
        renderCell: ({ value }) => {
            const completedItems = value.filter((i) => i.completed).length;
            const valuePct = (completedItems / value.length) * 100;

            return (
                <Box alignItems="center" width="100%" position="relative">
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                        sx={{
                            position: 'absolute',
                            top: 2,
                            zIndex: 9999,
                            width: '100%',
                        }}
                    >
                        {`${completedItems} / ${value.length}`}
                    </Typography>
                    <LinearProgress
                        variant="determinate"
                        value={valuePct}
                        sx={{
                            'height': 25,
                            '& .MuiLinearProgress-bar': {
                                backgroundColor: getProgressColor(valuePct / 100),
                            },
                        }}
                    />
                </Box>
            );
        },
        sortable: false,
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 150,
        renderCell: ({ value }) => {
            return <span style={{ color: APPLICATION_STATUS_COLOR[value] }}>{value}</span>;
        },
    },
    {
        field: 'redirected',
        headerName: 'Redirected',
        width: 170,
        renderCell: ({ value }) => {
            return value ? <TaskAlt color="success" /> : <RadioButtonUnchecked color="error" />;
        },
    },
    {
        field: 'review',
        headerName: 'Review',
        width: 170,
        renderCell: ({ value }) => {
            return value ? <TaskAlt color="success" /> : <RadioButtonUnchecked color="error" />;
        },
    },
    {
        field: 'signed',
        headerName: 'Signed',
        width: 150,
        renderCell: ({ value }) => {
            return value ? <TaskAlt color="success" /> : <RadioButtonUnchecked color="error" />;
        },
    },
    {
        field: 'personalInfo.firstName',
        headerName: 'First Name',
        width: 200,
        valueGetter: ({ row }) => row?.personalInfo?.firstName || '',
        sortable: false,
    },
    {
        field: 'personalInfo.lastName',
        headerName: 'Last Name',
        width: 200,
        valueGetter: ({ row }) => row?.personalInfo?.lastName || '',
        sortable: false,
    },
    {
        field: 'personalInfo.email',
        headerName: 'Email Address',
        width: 350,
        valueGetter: ({ row }) => row?.personalInfo?.email || '',
        sortable: false,
    },
    {
        field: 'submittedAt',
        headerName: 'Submitted At',
        width: 200,
        valueGetter: ({ value }) => DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED),
    },
    {
        field: 'flags',
        headerName: 'Flags',
        width: 350,
        renderCell: ({ value }) => (
            <Box component="ul" display="flex" sx={{ paddingInlineStart: (theme) => theme.spacing(3) }}>
                {value.map((f) => (
                    <Chip label={f.flag.flagName} sx={{ mr: 1 }} key={f.flag.id} />
                ))}
            </Box>
        ),
    },
];

const ApplicationList = ({ defaultSearch, disableToolbar, mergeDefaultQuery = false, columnsShown = null, disableQuery = false }) => {
    const navigate = useNavigate();
    const query = useQuery();

    const querySearch = query.get('q');

    const [applications, setApplications] = useState({
        items: [],
        totalItems: 0,
    });
    const [search, setSearch] = useState(defaultSearch ?? querySearch ?? '(status:review) AND (ofacMatch:false AND factaFraudAlert:false)');
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(25);
    const [page, setPage] = useState(0);
    const [orderBy, setOrderBy] = useState({ id: 'desc' });

    useEffect(() => {
        if (!defaultSearch) {
            navigate({
                search: `q=${search}`,
            });
        }
    }, [defaultSearch, navigate, search]);

    useEffect(() => {
        if (search.trim().length < 2) {
            return;
        }

        setLoading(true);

        searchApplications(search, pageSize, page + 1, orderBy)
            .then((resp) => {
                setApplications(resp.data.applications);
                setLoading(false);
            })
            .catch((err) => {
                setApplications({});
                setLoading(false);
            });
    }, [search, pageSize, page, navigate, defaultSearch, orderBy]);

    return (
        <>
            <div style={{ height: '85vh', width: '100%' }}>
                <Helmet>
                    <title>Applications | Pandora</title>
                </Helmet>
                <DataGrid
                    rows={applications.items || []}
                    components={{ Toolbar: !disableToolbar ? TableToolbar : null }}
                    componentsProps={{ toolbar: { search, setSearch, mergeDefaultQuery, defaultSearch, disableQuery } }}
                    columns={columnsShown ? columns.filter((c) => columnsShown.includes(c.field)) : columns}
                    pageSize={pageSize}
                    page={page}
                    rowsPerPageOptions={[25, 50, 100]}
                    pagination
                    paginationMode="server"
                    rowCount={applications.totalItems}
                    onPageSizeChange={(p) => setPageSize(p)}
                    onPageChange={(p) => setPage(p)}
                    loading={loading}
                    onRowClick={(p) => navigate(`/applications/${p.id}`)}
                    disableMultipleSelection
                    sortingMode="server"
                    onSortModelChange={([model]) => model && setOrderBy({ [model.field]: model.sort })}
                    sortModel={Object.keys(orderBy).map((o) => ({ field: o, sort: orderBy[o] }))}
                    disableColumnFilter
                />
            </div>
        </>
    );
};

const QUERY_OPTIONS = [
    { name: 'Accepted', value: '(status:accepted) AND (ofacMatch:false AND factaFraudAlert:false)' },
    { name: 'Review', value: '(status:review) AND (ofacMatch:false AND factaFraudAlert:false)' },
    { name: 'Approved', value: 'status:approved' },
    { name: 'Originated', value: 'status:originated' },
    { name: 'OFAC', value: '(status:accepted OR status:review) AND (ofacMatch:true)' },
    { name: 'FACTA', value: '(status:accepted OR status:review) AND (factaFraudAlert:true)' },
];

const TableToolbar = ({ search, setSearch, mergeDefaultQuery, defaultSearch, disableQuery }) => {
    const [query, setQuery] = useState(search);

    useEffect(() => {
        setQuery(search);
    }, [search]);

    const handleSubmit = (e) => {
        e.preventDefault();

        setSearch(query);
    };

    const onSearchOptionChange = (value) => {
        if (!mergeDefaultQuery) {
            setSearch(value);
            return;
        }

        setSearch(`(${defaultSearch}) AND (${value})`);
    };

    return (
        <Toolbar sx={{ p: 1 }}>
            <Grid container spacing={3}>
                <Grid item>
                    <TextField
                        id="application-query-selector"
                        value={search}
                        onChange={(e) => onSearchOptionChange(e.target.value)}
                        variant="outlined"
                        select
                    >
                        {QUERY_OPTIONS.map((o, i) => (
                            <MenuItem key={i} value={o.value}>
                                {o.name}
                            </MenuItem>
                        ))}
                        {findIndex(QUERY_OPTIONS, { value: search }) < 0 && (
                            <MenuItem value={search} disabled>
                                Custom
                            </MenuItem>
                        )}
                    </TextField>
                </Grid>
                {!disableQuery && (
                    <Grid item sx={{ flexGrow: 1 }}>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                id="application-search"
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                variant="outlined"
                                fullWidth
                                error={search.trim().length < 2}
                                helperText={search.trim().length < 2 ? 'Query must be at least 2 characters in length' : ''}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <IconButton onClick={() => setSearch('')} size="large">
                                            <Close />
                                        </IconButton>
                                    ),
                                }}
                            />
                        </form>
                    </Grid>
                )}
            </Grid>
        </Toolbar>
    );
};

export default ApplicationList;
