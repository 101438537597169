import { useNotifications } from '@borrowworks/react-core-lib';
import { CircularProgress, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useUser } from 'contexts/UserProvider';
import { Duration } from 'luxon';
import React, { useCallback, useRef, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import { useNavigate } from 'react-router';
import { useInterval } from 'use-interval';

const formatTimeRemaining = (time) => {
    if (time < 0) {
        return '';
    }

    const duration = Duration.fromMillis(time).shiftTo('minutes', 'seconds');

    if (duration.minutes >= 1) {
        return `${duration.minutes} minutes`;
    }

    return `${duration.seconds} seconds`;
};

const SessionTimer = ({ idleTimeout, alertTimeout }) => {
    const [show, setShowDialog] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState(-1);
    const [tabTitle, setTabTitle] = useState(false);
    const idleTimer = useRef();
    const navigate = useNavigate();

    const { user } = useUser();
    const { showNotification } = useNotifications();

    const reset = () => {
        setShowDialog(false);
        setTimeRemaining(-1);
    };

    const checkInactivity = useCallback(() => {
        if (!user || timeRemaining < 0) {
            return;
        }

        document.title = `Inactivity logout in ${formatTimeRemaining(timeRemaining - 1000)} | Pandora`;
        if (timeRemaining > 0) {
            setTimeRemaining((t) => t - 1000);
            return;
        }

        reset();
        showNotification('You have been logged out due to inactivity.');
        navigate('/logout');
    }, [navigate, showNotification, timeRemaining, user]);

    useInterval(() => {
        checkInactivity();
    }, 1000);

    const onIdle = () => {
        if (!user) {
            return;
        }

        if (show) {
            return;
        }

        setTabTitle(document.title);
        setTimeRemaining(alertTimeout);
        setShowDialog(true);
    };

    const onActive = () => {
        if (user && tabTitle) {
            document.title = tabTitle;
        }

        reset();
    };

    return (
        <>
            <IdleTimer
                ref={idleTimer}
                timeout={idleTimeout - alertTimeout}
                onIdle={onIdle}
                crossTab={{
                    emitOnAllTabs: true,
                    type: 'localStorage',
                    channelName: 'agent-portal-idle-timer',
                    removeTimeout: 1000 * 60 * 60 * 24,
                }}
                onActive={onActive}
            />
            <Dialog open={show}>
                <DialogTitle>Inactivity Notice</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={4} justifyContent="center">
                        <Grid item alignSelf="center">
                            <CircularProgress size={64} variant="determinate" value={(timeRemaining / alertTimeout) * 100} />
                        </Grid>
                        <Grid item md={10}>
                            <Typography variant="body1" gutterBottom>
                                For security reasons, your session times out after you’ve been inactive for a while. Please press continue
                                to resume.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" gutterBottom>
                                {formatTimeRemaining(timeRemaining)} remaining.
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={onActive}>
                        Continue
                    </Button>
                    <Button color="primary" onClick={() => navigate('/logout')}>
                        Log Out
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

SessionTimer.defaultProps = {
    idleTimeout: 1000 * 60 * 15,
    alertTimeout: 1000 * 60,
};

export default SessionTimer;
