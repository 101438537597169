import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

const ConfirmationDialog = forwardRef((props, ref) => {
    const [open, setOpen] = useState(false);

    const { title, message, yesLabel, noLabel, onAction, children, maxWidth } = props;

    useImperativeHandle(ref, () => ({
        show() {
            setOpen(true);
        },
        hide() {
            setOpen(false);
        },
    }));

    const handleChange = useCallback(
        (option) => {
            setOpen(false);
            onAction(option);
        },
        [onAction]
    );

    return (
        <Dialog open={open} fullWidth maxWidth={maxWidth}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {message && <Typography>{message}</Typography>}
                {children}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={() => handleChange(true)}>
                    {yesLabel}
                </Button>
                <Button onClick={() => handleChange(false)}>{noLabel}</Button>
            </DialogActions>
        </Dialog>
    );
});

ConfirmationDialog.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string,
    maxWidth: PropTypes.string,
    yesLabel: PropTypes.string,
    noLabel: PropTypes.string,
    onAction: PropTypes.func.isRequired,
};

ConfirmationDialog.defaultProps = {
    yesLabel: 'Yes',
    noLabel: 'No',
    title: 'Are you sure?',
    maxWidth: 'lg',
};

export default ConfirmationDialog;
