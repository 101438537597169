import axios from 'axios';
import { stringify } from 'qs';

const httpClient = axios.create();

httpClient.interceptors.request.use((config) => {
    const token = JSON.parse(localStorage.getItem('ROCP_idToken'));
    if (token) {
        config.headers = {
            ...config.headers,
            'X-Authorization': `Bearer ${token}`,
        };
    }

    return config;
});

export const getMe = () => {
    return httpClient.get('/api/account/security/me.json');
};

export const searchApplications = (query = '', limit = 50, page = 1, orderBy = undefined) => {
    return httpClient.get(
        '/api/applications/search.json?' +
            stringify({
                q: query,
                limit,
                page,
                orderBy,
            })
    );
};

export const findLastApplicationByEmailAndSsn4 = (email, ssn4) => {
    return httpClient.get(`/api/applications/find/email/${email}/ssn4/${ssn4}.json`);
};

export const getApplication = (id) => {
    return httpClient.get(`/api/applications/${id}.json`);
};

export const getApplicationByLoanNumber = (loanNumber) => {
    return httpClient.get(`/api/applications/find/loanNumber/${loanNumber}.json`);
};

export const createApplication = (application) => {
    return httpClient.post(`/api/applications/create.json`, application);
};

export const getApplicationRefinance = (id) => {
    return httpClient.get(`/api/applications/${id}/refinance.json`);
};

export const cloneApplication = (id) => {
    return httpClient.get(`/api/applications/${id}/clone.json`);
};

export const checkExpressEligibility = (id) => {
    return httpClient.get(`/api/applications/${id}/express-eligible-check.json`);
};

export const getExpressEligibleApplication = (id) => {
    return httpClient.get(`/api/applications/${id}/express-eligible.json`);
};

export const clearApplicationOfac = (id) => {
    return httpClient.post(`/api/applications/${id}/clear-ofac-match.json`);
};

export const clearApplicationFacta = (id) => {
    return httpClient.post(`/api/applications/${id}/clear-facta-fraud-alert.json`);
};

export const updateApplication = (id, data) => {
    return httpClient.patch(`/api/applications/${id}/update`, data);
};

export const updatePersonalInfo = (id, data) => {
    return httpClient.put(`/api/applications/${id}/personal-info`, data);
};

export const patchPersonalInfo = (id, data) => {
    return httpClient.patch(`/api/applications/${id}/personal-info`, data);
};

export const patchOriginatedPersonalInfo = (id, data) => {
    return httpClient.patch(`/api/applications/originated/${id}/personal-info`, data);
};

export const updateIncomeInfo = (id, data) => {
    return httpClient.put(`/api/applications/${id}/income`, data);
};

export const updateBankInfo = (id, data) => {
    return httpClient.put(`/api/applications/${id}/bank`, data);
};

export const updateFundingOption = (id, data) => {
    return (
        httpClient
            //WHY???
            .put(`/api/applications/funding-options/${id}`, data)
    );
};

export const updatePaymentOption = (id, data) => {
    return (
        httpClient
            //WHY???
            .put(`/api/applications/payment-options/${id}`, data)
    );
};

export const addPromotion = (applicationId, promotionId) => {
    return httpClient.post(`/api/applications/${applicationId}/promotions/${promotionId}/add`);
};

export const removePromotion = (applicationId, promotionId) => {
    return httpClient.delete(`/api/applications/${applicationId}/promotions/${promotionId}/delete`);
};

export const addApplicationFlag = (applicationId, flagId) => {
    return (
        httpClient
            //LOL WUT?
            .post(`/api/applications/${applicationId}/${flagId}/add.json`, {
                application: applicationId,
                flag: flagId,
            })
    );
};

export const removeApplicationFlag = (applicationId, flagId) => {
    return httpClient.post(`/api/applications/${applicationId}/flags/${flagId}/delete.json`);
};

export const getApplicationFlags = (applicationId) => {
    return httpClient.get(`/api/applications/${applicationId}/flags.json`);
};

export const getNotes = (applicationId, filterBy = null, page = 1) => {
    let params = {
        page,
    };

    if (filterBy) {
        params = {
            filterBy,
            ...params,
        };
    }

    return httpClient.get(`/api/notes/${applicationId}.json?${stringify(params)}`);
};

export const addNote = (applicationId, content, type = 'agent') => {
    return httpClient.post(`/api/notes/${applicationId}/add.json`, {
        content,
        type,
    });
};

export const getChecklists = (applicationId) => {
    return httpClient.get(`/api/applications/${applicationId}/checklists.json`);
};

export const getDisclosures = (applicationId, limit = 50, page = 1) => {
    return httpClient.get(`/api/applications/${applicationId}/disclosures.json?` + stringify({ limit, page }));
};

export const createDisclosure = (applicationId, data) => {
    return httpClient.post(`/api/applications/${applicationId}/disclosures/create.json`, data);
};

export const previewDisclosure = (applicationId, storeDocumentId) => {
    return httpClient.post(`/api/applications/${applicationId}/disclosures/${storeDocumentId}/preview`);
};

export const getUnderwritingTransactions = (applicationId) => {
    return httpClient.get(`/api/applications/${applicationId}/underwriting.json`);
};

export const getUnderwritingTransactionResponse = (applicationId, transaction) => {
    return httpClient.get(`/api/applications/${applicationId}/underwriting/${transaction}.json`);
};

export const getUnderwritingReasons = (applicationId) => {
    return httpClient.get(`/api/applications/${applicationId}/reasons.json`);
};

export const getSignatureEnvelopes = (applicationId) => {
    return httpClient.get(`/api/signature/applications/${applicationId}/signature-envelopes`);
};

export const generateSignatureEnvelope = (applicationId) => {
    return httpClient.post(`/api/signature/applications/${applicationId}/documents`);
};

export const getSignatureDownloadUrl = (applicationId, envelopeId) => {
    return httpClient.get(`/api/signature/applications/${applicationId}/signature-envelopes/${envelopeId}/download-url`);
};

export const voidSignatureEnvelope = (applicationId, envelopeId) => {
    return httpClient.delete(`/api/signature/applications/${applicationId}/signature-envelopes/${envelopeId}`);
};

export const unVoidSignatureEnvelope = (applicationId, envelopeId) => {
    return httpClient.patch(`/api/signature/applications/${applicationId}/signature-envelopes/${envelopeId}/unvoid`);
};

export const getDocuments = (applicationId) => {
    return httpClient.get(`/api/documents/applications/${applicationId}/documents?_format=json`);
};

export const viewDocument = (applicationId, baseName) => {
    return httpClient.get(`/api/documents/applications/${applicationId}/documents/${baseName}/view`, { responseType: 'blob' });
};

export const deleteDocument = (applicationId, fileName) => {
    return httpClient.delete(`/api/documents/applications/${applicationId}/documents/${fileName}?_format=json`);
};

export const reuploadApplicationDocuments = (applicationId) => {
    return httpClient.post(`/api/applications/${applicationId}/documents/reupload`);
};

export const underwriteApplication = (applicationId) => {
    return httpClient.post(`/api/applications/${applicationId}/underwrite.json`);
};

export const getStoreDocuments = (storeId, filterBy = null, page = 1) => {
    let params = {
        page,
    };

    if (filterBy) {
        params = {
            filterBy,
            ...params,
        };
    }

    return httpClient.get(`/api/stores/${storeId}/documents.json?${stringify(params)}`);
};

export const getStoreLoanSettings = (storeId) => {
    return httpClient.get(`/api/stores/${storeId}/loan-settings.json`);
};

export const uploadDocument = (applicationId, file, progressCallback) => {
    const form = new FormData();
    form.append('file', file);
    return httpClient.post(`/api/documents/applications/${applicationId}/documents?_format=json`, form, {
        headers: {
            'Content-Type': `multipart/form-data`,
        },
        onUploadProgress: progressCallback,
    });
};

export const getChecklistItems = (applicationId, checklistId) => {
    return httpClient.get(`/api/applications/${applicationId}/checklists/${checklistId}/items.json`);
};

export const updateChecklistItem = (applicationId, checklistId, checklistItemId, data) => {
    return httpClient.patch(`/api/applications/${applicationId}/checklists/${checklistId}/items/${checklistItemId}/edit.json`, data);
};

export const reindexApplication = (applicationId) => {
    return httpClient.post(`/api/applications/${applicationId}/index.json`);
};

export const repairApplication = (applicationId) => {
    return httpClient.post(`/api/applications/${applicationId}/repair`);
};

export const approveApplication = (applicationId) => {
    return httpClient.post(`/api/applications/${applicationId}/approve.json`);
};

export const originateApplication = (applicationId) => {
    return httpClient.post(`/api/applications/${applicationId}/originate.json`);
};

export const withdrawApplication = (applicationId, reasons) => {
    return httpClient.post(`/api/applications/${applicationId}/withdraw.json`, { reasons });
};

export const unWithdrawApplication = (applicationId) => {
    return httpClient.post(`/api/applications/${applicationId}/unwithdraw.json`);
};

export const voidApplication = (applicationId) => {
    return httpClient.post(`/api/applications/${applicationId}/void.json`);
};

export const reviewApplication = (applicationId, reasonCodes = null) => {
    return httpClient.post(`/api/applications/${applicationId}/review.json`, { reasonCodes });
};

export const ignoreApplication = (applicationId) => {
    return httpClient.post(`/api/applications/${applicationId}/ignore.json`);
};

export const getLoanAgreementPreview = (applicationId) => {
    return httpClient.post(`/api/signature/applications/${applicationId}/preview`, null, { responseType: 'arraybuffer' });
};

export const searchLeadProviders = (query = '*', limit = 50, page = 1, orderBy = undefined) => {
    return httpClient.get(
        '/api/lead-providers/.json?' +
            stringify({
                q: query,
                limit,
                page,
                orderBy,
            })
    );
};

export const searchLeadProviderCplOverrides = (query = '*', limit = 50, page = 1, orderBy = undefined) => {
    return httpClient.get(
        '/api/lead-provider-cpl-overrides/.json?' +
            stringify({
                q: query,
                limit,
                page,
                orderBy,
            })
    );
};

export const addLeadProviderCplOverride = (values) => {
    return httpClient.post(`/api/lead-provider-cpl-overrides/add.json`, values);
};

export const getLeadProvider = (leadProviderId) => {
    return httpClient.get(`/api/lead-providers/${leadProviderId}.json`);
};

export const addLeadProvider = (values) => {
    return httpClient.post(`/api/lead-providers/add.json`, values);
};

export const editLeadProvider = (leadProviderId, values) => {
    return httpClient.post(`/api/lead-providers/${leadProviderId}.json`, values);
};

export const getStrategySelector = () => {
    return httpClient.get(`/api/underwriting/strategy-selection.json`);
};

export const getStrategySelectorHistory = (id) => {
    return httpClient.get(`/api/underwriting/strategy-selection/${id}/history.json`);
};

export const validateStrategySelector = (template) => {
    return httpClient.post(`/api/underwriting/strategy-selection/validate.json`, { template });
};

export const saveStrategySelector = (template, comment) => {
    return httpClient.post(`/api/underwriting/strategy-selection.json`, { template, comment });
};

export const searchDocumentTemplates = (query = '', limit = 50, page = 1) => {
    return httpClient.get(
        '/api/templates/.json?' +
            stringify({
                q: query,
                limit,
                page,
            })
    );
};

export const previewDocumentTemplate = (templateId) => {
    return httpClient.post(`/api/templates/${templateId}/preview.json`);
};

export const getDocumentTemplate = (templateId) => {
    return httpClient.get(`/api/templates/${templateId}.json`);
};

export const editDocumentTemplate = (templateId, values) => {
    return httpClient.post(`/api/templates/${templateId}/edit.json`, values);
};

export const getDocumentTemplateHistoryList = (templateId) => {
    return httpClient.get(`/api/templates/${templateId}/history.json`);
};

export const getDocumentTemplateHistory = (historyId) => {
    return httpClient.get(`/api/templates/history/${historyId}.json`);
};

export const searchBlacklists = (query = undefined, limit = 50, page = 1) => {
    return httpClient.get(
        `/api/blacklists/${!query ? '' : 'search'}.json?` +
            stringify({
                q: query,
                limit,
                page,
            })
    );
};

export const getBlacklistsByType = (type, limit = 50, page = 1) => {
    let filterBy = { blacklistType: type };

    return httpClient.get(
        `/api/blacklists/.json?${stringify({
            filterBy,
            limit,
            page,
        })}`
    );
};

export const deleteFromBlacklists = (id) => {
    return httpClient.delete(`/api/blacklists/${id}/delete.json`);
};

export const createBlacklistsItem = (values) => {
    return httpClient.post(`/api/blacklists/add.json`, values);
};

export const updateBlacklistsItem = (id, values) => {
    return httpClient.post(`/api/blacklists/${id}/edit.json`, values);
};

export const getValidBlacklistsTypes = () => {
    return httpClient.get(`/api/blacklists/types.json`);
};

export const searchAbaBlacklists = (query = undefined, limit = 50, page = 1) => {
    return httpClient.get(
        `/api/blacklisted/abas/${!query ? '' : 'search'}.json?` +
            stringify({
                q: query,
                limit,
                page,
            })
    );
};

export const deleteAbaFromBlacklist = (aba) => {
    return httpClient.delete(`/api/blacklisted/abas/${aba}/delete.json`);
};

export const createAbaBlacklistItem = (values) => {
    return httpClient.post(`/api/blacklisted/abas/add.json`, values);
};

export const updateAbaBlacklistItem = (aba, values) => {
    return httpClient.post(`/api/blacklisted/abas/${aba}.json`, values);
};

export const searchBankAccountBlacklists = (query = undefined, limit = 50, page = 1) => {
    return httpClient.get(
        `/api/blacklisted/bankaccts/${!query ? '' : 'search'}.json?` +
            stringify({
                q: query,
                limit,
                page,
            })
    );
};

export const deleteBankAccountFromBlacklist = (account) => {
    return httpClient.delete(`/api/blacklisted/bankaccts/${account}/delete.json`);
};

export const createBankAccountBlacklistItem = (values) => {
    return httpClient.post(`/api/blacklisted/bankaccts/.json`, values);
};

export const updateBankAccountBlacklistItem = (account, values) => {
    return httpClient.post(`/api/blacklisted/bankaccts/${account}.json`, values);
};

export const searchSsnBlacklists = (query = undefined, limit = 50, page = 1) => {
    return httpClient.get(
        `/api/blacklisted/ssns/${!query ? '' : 'search'}.json?` +
            stringify({
                q: query,
                limit,
                page,
            })
    );
};

export const deleteSsnFromBlacklist = (account) => {
    return httpClient.delete(`/api/blacklisted/ssns/${account}/delete.json`);
};

export const createSsnBlacklistItem = (values) => {
    return httpClient.post(`/api/blacklisted/ssns/.json`, values);
};

export const updateSsnBlacklistItem = (account, values) => {
    return httpClient.post(`/api/blacklisted/ssns/${account}.json`, values);
};

export const searchPostalCodeBlacklists = (query = undefined, limit = 50, page = 1) => {
    return httpClient.get(
        `/api/blacklisted/postalcodes/${!query ? '' : 'search'}.json?` +
            stringify({
                q: query,
                limit,
                page,
            })
    );
};

export const deletePostalCodeFromBlacklist = (id) => {
    return httpClient.delete(`/api/blacklisted/postalcodes/${id}/delete.json`);
};

export const updatePostalCodeBlacklistItem = (id, values) => {
    return httpClient.post(`/api/blacklisted/postalcodes/${id}/edit.json`, values);
};

export const createBlacklistedPostalCodeItem = (values) => {
    return httpClient.post(`/api/blacklisted/postalcodes/add.json`, values);
};

export const searchDriversLicenseBlacklists = (query = undefined, limit = 50, page = 1) => {
    return httpClient.get(
        `/api/blacklisted/dls/${!query ? '' : 'search'}.json?` +
            stringify({
                q: query,
                limit,
                page,
            })
    );
};

export const listStates = (query = undefined, limit = 50, page = 1) => {
    return httpClient.get(
        `/api/states/.json?` +
            stringify({
                filterBy: query,
                limit,
                page,
            })
    );
};

export const searchStates = (q) => {
    return httpClient.get(`/api/states/search.json?q=${q}`);
};

export const addState = (state) => {
    return httpClient.post('/api/states/.json', state);
};

export const editState = (code, state) => {
    return httpClient.put(`/api/states/${code}.json`, state);
};

export const deleteState = (code) => {
    return httpClient.delete(`/api/states/${code}.json`);
};

export const searchBlacklistHistory = (query = undefined, limit = 50, page = 1) => {
    return httpClient.get(
        `/api/blacklists/history/.json?` +
            stringify({
                filterBy: query,
                limit,
                page,
            })
    );
};

export const removeFlag = (flagId) => {
    return httpClient.post(`/api/flags/${flagId}/delete.json`);
};

export const addFlag = (values) => {
    return httpClient.post(`/api/flags/add.json`, values);
};

export const searchFlags = (query = '*', limit = 50, page = 1) => {
    return httpClient.get(
        '/api/flags/.json?' +
            stringify({
                q: query,
                limit,
                page,
            })
    );
};

export const addWebhook = (values) => {
    return httpClient.post(`/api/webhooks/add.json`, values);
};

export const getWebhook = (id) => {
    return httpClient.get(`/api/webhooks/${id}.json`);
};

export const saveWebhook = (id, values) => {
    return httpClient.post(`/api/webhooks/${id}/edit.json`, values);
};

export const deleteWebhook = (id) => {
    return httpClient.delete(`/api/webhooks/${id}.json`);
};

export const searchWebhooks = (query = '*', limit = 50, page = 1) => {
    return httpClient.get(
        '/api/webhooks/.json?' +
            stringify({
                q: query,
                limit,
                page,
            })
    );
};

export const searchUnderwritingStrategyFamilies = (query = '*', limit = 50, page = 1) => {
    return httpClient.get(
        '/api/underwriting/strategy-families/.json?' +
            stringify({
                q: query,
                limit,
                page,
            })
    );
};

export const getAllUnderwritingStrategyFamilies = () => {
    return httpClient.get('/api/underwriting/strategy-families/all.json');
};

export const getUnderwritingStrategyFamily = (id) => {
    return httpClient.get(`/api/underwriting/strategy-families/${id}.json`);
};

export const addUnderwritingStrategyFamily = (values) => {
    return httpClient.post(`/api/underwriting/strategy-families/add.json`, values);
};

export const updateUnderwritingStrategyFamily = (id, values) => {
    return httpClient.post(`/api/underwriting/strategy-families/${id}/edit.json`, values);
};

export const deleteUnderwritingStrategyFamily = (id, values) => {
    return httpClient.delete(`/api/underwriting/strategy-families/${id}/delete.json`);
};

export const searchUnderwritingStrategies = (query = '*', limit = 50, page = 1) => {
    return httpClient.get(
        '/api/underwriting/strategies/.json?' +
            stringify({
                q: query,
                limit,
                page,
            })
    );
};

export const getUnderwritingStrategy = (id) => {
    return httpClient.get(`/api/underwriting/strategies/${id}.json`);
};

export const getUnderwritingStrategyHistory = (id) => {
    return httpClient.get(`/api/underwriting/strategies/${id}/history.json`);
};

export const addUnderwritingStrategy = (values) => {
    return httpClient.post(`/api/underwriting/strategies/add.json`, values);
};

export const updateUnderwritingStrategy = (id, values) => {
    return httpClient.post(`/api/underwriting/strategies/${id}/edit.json`, values);
};

export const updateFamilyOfUnderwritingStrategy = (id, values) => {
    return httpClient.post(`/api/underwriting/strategies/${id}/family.json`, values);
};

export const createDriversLicenseBlacklistItem = (values) => {
    return httpClient.post(`/api/blacklisted/dls/.json`, values);
};

export const updateDriversLicenseBlacklistItem = (account, values) => {
    return httpClient.post(`/api/blacklisted/dls/${account}.json`, values);
};

export const deleteDriversLicenseFromBlacklist = (account) => {
    return httpClient.delete(`/api/blacklisted/dls/${account}/delete.json`);
};

export const searchCampaignBulkUploads = (query = '*', limit = 50, page = 1) => {
    return httpClient.get(
        '/api/campaigns/bulk-uploads/.json?' +
            stringify({
                q: query,
                limit,
                page,
            })
    );
};

export const addCampaignBulkUpload = (file, uploadName, progressCallback) => {
    const form = new FormData();
    form.append('campaignBulkUploadFile', file);
    form.append('campaignBulkUploadName', uploadName);

    return httpClient.post(`/api/campaigns/bulk-uploads/add.json`, form, {
        headers: {
            'Content-Type': `multipart/form-data`,
        },
        onUploadProgress: progressCallback,
    });
};

export const createAndVerifyCampaignBulkUpload = (file, uploadName) => {
    const form = new FormData();
    form.append('campaignBulkUploadFile', file);
    form.append('campaignBulkUploadName', uploadName);

    return httpClient.post(`/api/campaigns/bulk-uploads/verify.json`, form, {
        headers: {
            'Content-Type': `multipart/form-data`,
        },
    });
};

export const verifyCampaignBulkUpload = (id, name) => {
    return httpClient.post(`/api/campaigns/bulk-uploads/verify/${id}.json`, {
        campaignBulkUploadName: name,
    });
};

export const processCampaignBulkUpload = (id, omitRows = []) => {
    return httpClient.post(`/api/campaigns/bulk-uploads/${id}/process.json`, { rows: omitRows });
};

export const getCampaignBulkUpload = (id) => {
    return httpClient.get(`/api/campaigns/bulk-uploads/${id}.json`);
};

export const updateCampaignBulkUpload = (id, values) => {
    return httpClient.post(`/api/campaigns/bulk-uploads/${id}/edit.json`, values);
};

export const uploadCampaignBulkUploadFile = (id, file, uploadName, progressCallback) => {
    const form = new FormData();
    form.append('campaignBulkUploadFile', file);
    form.append('campaignBulkUploadName', uploadName);

    return httpClient.post(`/api/campaigns/bulk-uploads/${id}/edit.json`, form, {
        headers: {
            'Content-Type': `multipart/form-data`,
        },
        onUploadProgress: progressCallback,
    });
};

export const searchCampaigns = (query = '*', limit = 50, page = 1, orderBy = undefined) => {
    return httpClient.get(
        '/api/campaigns/search.json?' +
            stringify({
                q: '' + query,
                limit,
                page,
                orderBy,
            })
    );
};

export const getCampaign = (id) => {
    return httpClient.get(`/api/campaigns/${id}.json`);
};

export const addCampaign = (values) => {
    return httpClient.post(`/api/campaigns/add.json`, values);
};

export const updateCampaign = (id, values) => {
    return httpClient.post(`/api/campaigns/${id}/edit.json`, values);
};

export const deleteCampaign = (id, values) => {
    return httpClient.delete(`/api/campaigns/${id}.json`, values);
};

export const searchOffers = (query = '*', limit = 50, page = 1, orderBy = undefined) => {
    return httpClient.get(
        '/api/offers/search.json?' +
            stringify({
                q: query,
                limit,
                page,
                orderBy,
            })
    );
};

export const getOffer = (id) => {
    return httpClient.get(`/api/offers/${id}.json`);
};

export const getOfferTypes = () => {
    return httpClient.get(`/api/offers/offer-types.json`);
};

export const addOffer = (values) => {
    return httpClient.post('/api/offers/add.json', values);
};

export const updateOffer = (id, values) => {
    return httpClient.put(`/api/offers/${id}/edit.json`, values);
};

export const deleteOffer = (id, values) => {
    return httpClient.delete(`/api/offers/${id}.json`, values);
};

export const addUnderwritingReason = (values) => {
    return httpClient.post(`/api/underwriting/reasons/add.json`, values);
};

export const getUnderwritingReason = (id) => {
    return httpClient.get(`/api/underwriting/reasons/${id}.json`);
};

export const saveUnderwritingReason = (id, values) => {
    return httpClient.post(`/api/underwriting/reasons/${id}/edit.json`, values);
};

export const deleteUnderwritingReason = (id) => {
    return httpClient.post(`/api/underwriting/reasons/${id}/delete.json`);
};

export const searchUnderwritingReasons = (query = '*', limit = 50, page = 1) => {
    return httpClient.get(
        '/api/underwriting/reasons/.json?' +
            stringify({
                q: query,
                limit,
                page,
            })
    );
};

export const searchAffiliates = (query = '*', limit = 50, page = 1) => {
    return httpClient.get(
        '/api/applications/affiliates.json?' +
            stringify({
                q: query,
                limit,
                page,
            })
    );
};

export const searchStores = (query = '*', limit = 50, page = 1) => {
    return httpClient.get(
        '/api/stores/.json?' +
            stringify({
                q: query,
                limit,
                page,
            })
    );
};

export const searchAccountTypes = (query = '*', limit = 50, page = 1) => {
    return httpClient.get(
        '/api/applications/account-types.json?' +
            stringify({
                q: query,
                limit,
                page,
            })
    );
};

export const searchPayTypes = (query = '*', limit = 50, page = 1) => {
    return httpClient.get(
        '/api/applications/pay-types.json?' +
            stringify({
                q: query,
                limit,
                page,
            })
    );
};

export const searchEmploymentTypes = (query = '*', limit = 50, page = 1) => {
    return httpClient.get(
        '/api/applications/employment-types.json?' +
            stringify({
                q: query,
                limit,
                page,
            })
    );
};

export const searchIncomeTypes = (query = '*', limit = 50, page = 1) => {
    return httpClient.get(
        '/api/applications/income-types.json?' +
            stringify({
                q: query,
                limit,
                page,
            })
    );
};

export const searchIndustryTypes = (query = '*', limit = 50, page = 1) => {
    return httpClient.get(
        '/api/applications/industry-types.json?' +
            stringify({
                q: query,
                limit,
                page,
            })
    );
};

export const searchResidenceTypes = (query = '*', limit = 50, page = 1) => {
    return httpClient.get(
        '/api/applications/residence-types.json?' +
            stringify({
                q: query,
                limit,
                page,
            })
    );
};

export const getSignatureDownloadContent = (id) => {
    return httpClient.get(`/api/signature/${id}/download/pdf`, {
        responseType: 'blob',
        headers: {
            Accept: 'application/pdf',
        },
    });
};

export const getSignatureStatuses = (envelopeId) => {
    return httpClient.get(`/api/signature/${envelopeId}/statuses`);
};

export const logOut = () => {
    return httpClient.post('/api/account/security/logout?_format=json');
};

export const downloadFile = (uri) => {
    return httpClient.get(uri, { responseType: 'blob' });
};

export const getUnderwritingProducts = () => {
    return httpClient.get('/api/underwriting/products/.json');
};

export const getUnderwritingProduct = (id) => {
    return httpClient.get(`/api/underwriting/products/${id}.json`);
};

export const addUnderwritingProduct = (product) => {
    return httpClient.post('/api/underwriting/products/.json', product);
};

export const updateUnderwritingProduct = (id, product) => {
    return httpClient.post(`/api/underwriting/products/${id}.json`, product);
};

export const deleteUnderwritingProduect = (id) => {
    return httpClient.delete(`/api/underwriting/products/${id}.json`);
};
