import React, { createContext, useContext } from 'react';
import _refiner from 'refiner-js';
import config from 'config';

const FeedbackContext = createContext(null);

const FeedbackProvider = ({ children }) => {
    const { refiner } = config;

    _refiner('setProject', refiner.projectId);

    _refiner('onComplete', function (formId, responseData) {
        const responses = {
            account: {},
        };

        Object.keys(responseData).forEach((key) => {
            responses.account[key] = responseData[key];
        });
        _refiner('identifyUser', responses);
    });

    const identifyFeedbackUser = (id, email, loan, environment, portfolio) => {
        _refiner('identifyUser', {
            id: id,
            email: email,
            account: {
                environment: environment,
                loan_number: loan.loanNumber,
                portfolio: portfolio,
            },
        });
    };

    const resetFeedbackUser = () => _refiner('resetUser');

    const openSurvey = (survey) => _refiner('showForm', survey);

    const closeSurvey = (survey) => _refiner('closeForm', survey);

    return (
        <FeedbackContext.Provider
            value={{
                _refiner,
                identifyFeedbackUser,
                resetFeedbackUser,
                openSurvey,
                closeSurvey,
            }}
        >
            {children}
        </FeedbackContext.Provider>
    );
};

const useFeedback = () => useContext(FeedbackContext);

export { FeedbackContext, FeedbackProvider, useFeedback };
