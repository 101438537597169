import env from '@beam-australia/react-env';
import { useEffect, useState } from 'react';

const portfolioName = env('PORTFOLIO_NAME');
const environment = require(`./themes/${portfolioName}/environment`);

export const getCdnAsset = (relativePath) => {
    return `${environment.default?.cdn?.URL}/${portfolioName}${relativePath}`;
};

export const getLocalAsset = (relativePath) => {
    return import(`themes/${portfolioName}/assets/${relativePath}`);
};

export const useLocalAsset = (path) => {
    const [asset, setAsset] = useState(null);

    useEffect(() => {
        getLocalAsset(path).then((module) => setAsset(module.default));
    });

    return asset;
};

export default environment.default;
