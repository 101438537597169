import React from 'react';
import { Helmet } from 'react-helmet-async';

const Head = () => (
    <Helmet preserved>
        <title>| Pandora</title>
    </Helmet>
);

export default Head;
