import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Collapse,
    Fab,
    Grid,
    List,
    ListItemButton,
    ListItemText,
    TextField,
} from '@mui/material';
import { getUnderwritingProducts } from 'services/PandoraApiService';
import { TransitionGroup } from 'react-transition-group';
import FuzzySearch from 'fuzzy-search';
import { useDebouncedCallback } from 'use-debounce';
import { Link as RouterLink } from 'react-router-dom';
import { Add, Search } from '@mui/icons-material';

const ProductItem = ({ product }) => {
    return (
        <ListItemButton divider component={RouterLink} to={`/underwriting/products/${product.id}`}>
            <ListItemText primary={product.underwriterName} secondary={product.product} />${product.transactionCost}{' '}
            {/* Do not use currency formatter since some products use fractions of a cent */}
        </ListItemButton>
    );
};

const ProductList = () => {
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [search, setSearch] = useState('');
    const [searchInput, setSearchInput] = useState('');

    useEffect(() => {
        setLoading(true);
        getUnderwritingProducts().then((resp) => {
            setProducts(resp.data);
            setLoading(false);
        });
    }, []);

    const searchChanged = useDebouncedCallback((value) => {
        setSearch(value);
    }, 200);

    return (
        <>
            <Helmet>
                <title>{`Manage Underwriting Products | Pandora`}</title>
            </Helmet>
            <Card>
                <CardHeader
                    title="Underwriting Products"
                    subheader={
                        <Grid container alignItems="center" sx={{ mt: 2 }} justifyContent="space-between">
                            <Grid item xs={10}>
                                <TextField
                                    fullWidth
                                    color="secondary"
                                    InputProps={{
                                        startAdornment: <Search />,
                                    }}
                                    onChange={(e) => searchChanged(e.target.value) || setSearchInput(e.target.value)}
                                    value={searchInput}
                                    placeholder="Search by underwriter or product name"
                                />
                            </Grid>
                            <Grid item>
                                <Fab color="primary" size="small" to="/underwriting/products/new" component={RouterLink}>
                                    <Add />
                                </Fab>
                            </Grid>
                        </Grid>
                    }
                />
                <CardContent>
                    {loading && (
                        <Grid container justifyContent="center" alignContent="center">
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    )}
                    <List>
                        <TransitionGroup>
                            {(new FuzzySearch(products, ['underwriterName', 'product']).search(search) || []).map((d, i) => (
                                <Collapse key={d.id}>
                                    <ProductItem product={d} />
                                </Collapse>
                            ))}
                        </TransitionGroup>
                        {products?.length < 1 && !loading && <span>No data available.</span>}
                        {loading && (
                            <Grid container justifyContent="center" alignItems="center">
                                <Grid item>
                                    <CircularProgress />
                                </Grid>
                            </Grid>
                        )}
                    </List>
                </CardContent>
            </Card>
        </>
    );
};

export default ProductList;
