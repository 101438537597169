import { Card, Button, CardContent, Grid, Typography, Box, CardActions } from '@mui/material';
import { Help } from '@mui/icons-material';
import React from 'react';
import { useNavigate } from 'react-router';
import { useUser } from 'contexts/UserProvider';

const NotFound = () => {
    const navigate = useNavigate();
    const { user } = useUser();

    // This is a hack to keep the not found error page from showing when someone is refreshing the page
    if (!user) {
        return null;
    }

    return (
        <Grid container justifyContent="center">
            <Grid item>
                <Card sx={{ p: 3 }}>
                    <CardContent>
                        <Box display="flex" justifyContent="center" sx={{ mb: 3 }}>
                            <Help color="warning" sx={{ fontSize: 120 }} />
                        </Box>
                        <Typography variant="h6" align="center">
                            The page requested could not be found.
                        </Typography>
                        <Typography variant="subtitle1" align="center">
                            Please verify the URL requested is valid and that you have the correct permissions.
                        </Typography>
                        <br />
                    </CardContent>
                    <CardActions>
                        <Box display="flex" justifyContent="center" width="100%">
                            <Button variant="outlined" color="primary" onClick={() => navigate(-1)}>
                                Go Back
                            </Button>
                        </Box>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    );
};

export default NotFound;
