import { useNotifications } from '@borrowworks/react-core-lib';
import { Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { FastField, Form, Formik } from 'formik';
import { TextField as FormikTextField } from 'formik-mui';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
    addUnderwritingProduct,
    updateUnderwritingProduct,
    deleteUnderwritingProduect,
    getUnderwritingProduct,
} from 'services/PandoraApiService';
import { Helmet } from 'react-helmet-async';

const EditProduct = () => {
    const [product, setProduct] = useState({});
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    const { showNotification } = useNotifications();
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!id) {
            return;
        }

        setLoading(true);
        getUnderwritingProduct(id).then((resp) => {
            setProduct(resp.data.underwritingProduct);
            setLoading(false);
        });
    }, [id]);

    const onSave = useCallback(
        (values, formikProps) => {
            setSaving(true);
            let saveFunc = addUnderwritingProduct;

            if (id) {
                saveFunc = (values) => updateUnderwritingProduct(id, values);
            }

            saveFunc({
                underwriterName: values.underwriterName,
                product: values.product,
                transactionCost: values.transactionCost,
            })
                .then((resp) => {
                    showNotification(`Underwriting product ${id ? 'saved' : 'created'}`);
                    setSaving(false);
                })
                .catch((error) => {
                    showNotification(`Failed to save underwriting product.\n${error?.response?.data?.message}`);
                    setSaving(false);
                    formikProps.setSubmitting(false);
                });
        },
        [id, showNotification]
    );

    const onDelete = useCallback(() => {
        setSaving(true);
        deleteUnderwritingProduect(id)
            .then((resp) => {
                showNotification('Underwriting product deleted!');
                navigate('/underwriting/products');
            })
            .catch((error) => {
                showNotification(`Failed to delete underwriting product.\n${error?.response?.data?.message}`);
                setSaving(false);
            });
    }, [id, showNotification, navigate]);

    if (loading) {
        return (
            <Grid container justifyContent="center" alignItems="center">
                <CircularProgress size={48} />
            </Grid>
        );
    }

    return (
        <>
            <Helmet>
                <title>{`Edit Underwriting Product: ${product?.underwriterName} | Pandora`}</title>
            </Helmet>
            <Card sx={{ padding: 1 }}>
                <CardHeader
                    disableTypography
                    title={
                        <Typography variant="h6" display="flex" alignItems="center">
                            <IconButton onClick={() => navigate(-1)} size="large">
                                <ArrowBack />
                            </IconButton>
                        </Typography>
                    }
                />
                <Formik
                    initialValues={{
                        ...Object.fromEntries(Object.entries(product).map(([k, v]) => [k, v === null ? '' : v])),
                    }}
                    onSubmit={onSave}
                >
                    <Form>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12} lg={6}>
                                    <FastField
                                        id="underwriter-name"
                                        name="underwriterName"
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                        label="Underwriter Name"
                                        component={FormikTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} lg={6}>
                                    <FastField
                                        id="product-name"
                                        name="product"
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        label="Product Name"
                                        component={FormikTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} lg={6}>
                                    <FastField
                                        id="transaction-cost"
                                        name="transactionCost"
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        label="Transaction Cost"
                                        component={FormikTextField}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" color="primary" type="submit" disabled={saving}>
                                Save
                                {saving && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: -1.5,
                                            marginLeft: -1.5,
                                        }}
                                    />
                                )}
                            </Button>
                            {id && (
                                <Button color="secondary" onClick={onDelete}>
                                    Delete
                                </Button>
                            )}
                        </CardActions>
                    </Form>
                </Formik>
            </Card>
        </>
    );
};

export default EditProduct;
